import React, { useState, useCallback } from 'react'
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Menu as MenuIcon } from '@mui/icons-material'

import MuiLink from '../../MuiLink'
import { useAuth } from 'context/auth/auth'
import { ProtectedComponent } from 'components'
import { User_Role_Enum } from 'api/generated'

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { t } = useTranslation()
  const { logout } = useAuth()

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <AppBar position='fixed'>
        <Toolbar>
          <Box
            display='flex'
            flex={1}
            justifyContent='space-between'
            alignItems='center'
          >
            <MuiLink to={`/`}>
              <img src='/images/large-logo-white.png' height={35} alt='Logo' />
            </MuiLink>

            <div>
              <IconButton
                aria-controls='menu-appbar'
                onClick={handleMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
              >
                <ProtectedComponent
                  allowedRoles={[
                    User_Role_Enum.Admin,
                    User_Role_Enum.TenantAdmin,
                    User_Role_Enum.LocationAdmin
                  ]}
                >
                  <MuiLink to='/alarms' color='inherit'>
                    <MenuItem onClick={handleClose}>{t('alarms')}</MenuItem>
                  </MuiLink>

                  <MuiLink to='/devices' color='inherit'>
                    <MenuItem onClick={handleClose}>{t('devices')}</MenuItem>
                  </MuiLink>

                  <MuiLink to='/agents' color='inherit'>
                    <MenuItem onClick={handleClose}>{t('agents')}</MenuItem>
                  </MuiLink>

                  <MuiLink to='/recipes' color='inherit'>
                    <MenuItem onClick={handleClose}>{t('recipes')}</MenuItem>
                  </MuiLink>

                  <MuiLink to='/cooks' color='inherit'>
                    <MenuItem onClick={handleClose}>{t('cooks')}</MenuItem>
                  </MuiLink>

                  <MuiLink to='/users' color='inherit'>
                    <MenuItem onClick={handleClose}>{t('users')}</MenuItem>
                  </MuiLink>

                  <MuiLink to='/locations' color='inherit'>
                    <MenuItem onClick={handleClose}>{t('locations')}</MenuItem>
                  </MuiLink>

                  <MuiLink to='/tenants' color='inherit'>
                    <MenuItem onClick={handleClose}>{t('tenants')}</MenuItem>
                  </MuiLink>
                </ProtectedComponent>

                <ProtectedComponent allowedRoles={[User_Role_Enum.Admin]}>
                  <MuiLink to='/user-devices-settings' color='inherit'>
                    <MenuItem onClick={handleClose}>
                      {t('deviceSettings')}
                    </MenuItem>
                  </MuiLink>
                </ProtectedComponent>

                <ProtectedComponent
                  allowedRoles={[
                    User_Role_Enum.Admin,
                    User_Role_Enum.TenantAdmin,
                    User_Role_Enum.LocationAdmin,
                    User_Role_Enum.Viewer
                  ]}
                >
                  <MuiLink to='/setup-dashboard' color='inherit'>
                    <MenuItem onClick={handleClose}>
                      {t('setupDashboard')}
                    </MenuItem>
                  </MuiLink>
                </ProtectedComponent>

                <MuiLink to='/profile' color='inherit'>
                  <MenuItem onClick={handleClose}>{t('profile')}</MenuItem>
                </MuiLink>

                <MenuItem onClick={logout}>{t('logOut')}</MenuItem>
              </Menu>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

export default Header
