import React, { useCallback } from 'react'
import { IconButton } from '@mui/material'
import {
  Brightness7 as Brightness7Icon,
  Brightness4 as Brightness4Icon
} from '@mui/icons-material'

import { useThemeType } from 'context/theme'

const ThemeSelect: React.FC = () => {
  const { setThemeType, isDarkTheme } = useThemeType()

  const toggleThemeType = useCallback(
    () => setThemeType(isDarkTheme ? 'light' : 'dark'),
    [setThemeType, isDarkTheme]
  )

  return (
    <IconButton onClick={toggleThemeType} color='inherit'>
      {isDarkTheme ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  )
}

export default ThemeSelect
