import {
  createTheme,
  PaletteColorOptions,
  Theme,
  ThemeOptions
} from '@mui/material/styles'
import type {} from '@mui/x-date-pickers/themeAugmentation'

const primary: PaletteColorOptions = {
  main: '#206548'
}

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary
  }
}

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary
  }
}

export default (type: 'light' | 'dark'): Theme =>
  type === 'dark'
    ? createTheme(darkThemeOptions)
    : createTheme(lightThemeOptions)
